import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import 'vue-search-select/dist/VueSearchSelect.css'
import '@/assets/vendor/fonts/boxicons.css'
import '@/assets/vendor/css/core.css'
import '@/assets/vendor/css/theme-default.css'
import '@/assets/css/demo.css'
import '@/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css'
import '@/assets/vendor/css/pages/page-auth.css'
import '@/assets/vendor/css/pages/page-auth.css'

Vue.config.productionTip = false
Vue.use(require('vue-moment'));
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
