import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../components/LoginForm.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/DashBoard.vue')
  },
  {
    path: '/prenotazioni/:type',
    name: 'prenotazioni',
    component: () => import('../views/PrenotazioniLista.vue'),
    props: true
  },
  {
    path: '/prenotazione/:type/:id',
    name: 'prenotazione',
    component: () => import('../views/PrenotazioneDettaglio.vue'),
    props: true
  },
  {
    path: '/prenotazionenew/:type/:tipo',
    name: 'prenotazionenew',
    component: () => import('../views/PrenotazioneDettaglioNew.vue'),
    props: true
  },
  {
    path: '/prenotazionemezzo/:type/:id',
    name: 'prenotazionemezzo',
    component: () => import('../views/PrenotazioneMezzo.vue'),
    props: true
  },
  {
    path: '/blocchi',
    name: 'blocchi',
    component: () => import('../views/BlocchiPrenotazioni.vue'),
    props: true
  },
  {
    path: '/blocchi/:id',
    name: 'bloccoedit',
    component: () => import('../views/BlocchiPrenotazioniEdit.vue'),
    props: true
  },
  {
    path: '/blocchi/new',
    name: 'blocconew',
    component: () => import('../views/BlocchiPrenotazioniNew.vue'),
    props: true
  },
  {
    path: '/blocconotturno',
    name: 'blocconotturno',
    component: () => import('../views/BloccoNotturno.vue'),
    props: true
  },
  {
    path: '/notturno',
    name: 'notturno',
    component: () => import('../views/NotturnoPrenotazioni.vue'),
    props: true
  },
  {
    path: '/notturno/:id',
    name: 'notturnoedit',
    component: () => import('../views/NotturnoPrenotazioniEdit.vue'),
    props: true
  },
  {
    path: '/notturno/new',
    name: 'notturnonew',
    component: () => import('../views/NotturnoPrenotazioniNew.vue'),
    props: true
  },

  {
    path: '/acquista',
    name: 'acquista',
    component: () => import('../views/AcquistaForm.vue'),
    props: true
  },
  {
    path: '/hotels',
    name: 'hotels',
    component: () => import('../views/HotelsForm.vue'),
    props: true
  },
  {
    path: '/hotels-gestione',
    name: 'hotelslista',
    component: () => import('../views/HotelLista.vue'),
    props: true
  },
  {
    path: '/hotels-gestione/new',
    name: 'hotelsnew',
    component: () => import('../views/HotelNew.vue'),
    props: true
  },
  {
    path: '/hotels-gestione/:id',
    name: 'hotelsedit',
    component: () => import('../views/HotelEdit.vue'),
    props: true
  },
  {
    path: '/anagrafica',
    name: 'anagrafica',
    component: () => import('../views/AnagraficaList.vue'),
    props: true
  },

  {
    path: '/percorsi',
    name: 'percorsi',
    component: () => import('../views/PercorsiLista.vue'),
    props: true
  },
  {
    path: '/percorsi/:id',
    name: 'percorsiedit',
    component: () => import('../views/PercorsiEdit.vue'),
    props: true
  },
  {
    path: '/percorsi/new',
    name: 'percorsinew',
    component: () => import('../views/PercorsiNew.vue'),
    props: true
  },

  {
    path: '/serviziaggiuntivi',
    name: 'serviziaggiuntivi',
    component: () => import('../views/ServiziLista.vue'),
    props: true
  },
  {
    path: '/serviziaggiuntivi/:id',
    name: 'serviziaggiuntiviedit',
    component: () => import('../views/ServiziEdit.vue'),
    props: true
  },
  {
    path: '/serviziaggiuntivi/new',
    name: 'serviziaggiuntivinew',
    component: () => import('../views/ServiziNew.vue'),
    props: true
  },

  {
    path: '/agenzie',
    name: 'agenzie',
    component: () => import('../views/AgenzieLista.vue'),
    props: true
  },
  {
    path: '/agenzie/new',
    name: 'agenzienew',
    component: () => import('../views/AgenzieNew.vue'),
    props: true
  },
  {
    path: '/agenzie/:id',
    name: 'agenzieedit',
    component: () => import('../views/AgenziaEdit.vue'),
    props: true
  },

  {
    path: '/pagamento',
    name: 'pagamento',
    component: () => import('../views/PagamentoPrenotazioni.vue'),
    props: true
  },

  {
    path: '/listini',
    name: 'listini',
    component: () => import('../views/ListiniLista.vue'),
    props: true
  },
  {
    path: '/listini/new',
    name: 'listininew',
    component: () => import('../views/ListiniNew.vue'),
    props: true
  },
  {
    path: '/listini/:id',
    name: 'listiniedit',
    component: () => import('../views/ListiniEdit.vue'),
    props: true
  },
  {
    path: '/mezzi',
    name: 'mezzi',
    component: () => import('../views/MezziLista.vue'),
    props: true
  },
  {
    path: '/mezzi/:id',
    name: 'mezziedit',
    component: () => import('../views/MezziEdit.vue'),
    props: true
  },
  {
    path: '/mezzi/new',
    name: 'mezzinew',
    component: () => import('../views/MezziNew.vue'),
    props: true
  },
  {
    path: '/contabilita/riepilogo',
    name: 'riepilogodocumenti',
    component: () => import('../views/RiepilogoDocumenti.vue'),
    props: true
  },
  {
    path: '/contabilita/fatture',
    name: 'fatture',
    component: () => import('../views/FattureView.vue'),
    props: true
  },
  {
    path: '/ddt/:type',
    name: 'ddt',
    component: () => import('../views/DdtLista.vue'),
    props: true
  },
  {
    path: '/contabilita/metodipagamento',
    name: 'metodipagamento',
    component: () => import('../views/MetodiPagamentoLista.vue'),
    props: true
  },
  {
    path: '/contabilita/metodipagamento',
    name: 'metodipagamentoadd',
    component: () => import('../views/MetodiPagamentoNew.vue'),
    props: true
  },
  {
    path: '/contabilita/metodipagamento/:id',
    name: 'metodipagamentoedit',
    component: () => import('../views/MetodiPagamentoEdit.vue'),
    props: true
  },
  {
    path: '/contabilita/codiciiva',
    name: 'codiciiva',
    component: () => import('../views/CodiciIvaLista.vue'),
    props: true
  },
  {
    path: '/contabilita/codiciiva/new',
    name: 'codiciivaadd',
    component: () => import('../views/CodiciIvaNew.vue'),
    props: true
  },
  {
    path: '/contabilita/codiciiva/:id',
    name: 'codiciivaedit',
    component: () => import('../views/CodiciIvaEdit.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
