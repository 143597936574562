<template>
    <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
          <div class="app-brand demo">
            <a class="app-brand-link">
              <span class="app-brand-text demo menu-text fw-bolder ms-2">VWT</span>
            </a>
            <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
              <i class="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
          </div>

          <div class="menu-inner-shadow"></div>

          <ul class="menu-inner py-1">
            <li class="menu-item">
              <router-link :to="{name:'dashboard'}" class="menu-link">
                <i class="menu-icon tf-icons bx bx-home-circle"></i>
                <div>Dashboard</div>
              </router-link>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='prenotazioni' || currentRouteName=='prenotazione' || currentRouteName=='prenotazionemezzo' || currentRouteName=='prenotazionenew'),open: (currentRouteName=='prenotazioni' || currentRouteName=='prenotazione' || currentRouteName=='prenotazionemezzo' || currentRouteName=='prenotazionenew')}">
              <router-link tag="a" :to="{name:'prenotazioni', params:{type: 1}}" class="menu-link menu-toggle">
                <i class='menu-icon tf-icons bx bx-cart' ></i>
                <div data-i18n="Account Settings">Prenotazioni</div>
              </router-link>
              <ul class="menu-sub">
                <li class="menu-item" :class="{active: (currentRouteParams.type=='1' && (currentRouteName=='prenotazioni' || currentRouteName=='prenotazione'))}">
                  <router-link tag="a" class="menu-link" :to="{name:'prenotazioni', params:{type: 1}}">
                    <div data-i18n="Without menu">Da assegnare</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteParams.type=='2' && (currentRouteName=='prenotazioni' || currentRouteName=='prenotazione'))}">
                  <router-link tag="a" class="menu-link" :to="{name:'prenotazioni', params:{type: 2}}">
                    <div data-i18n="Without navbar">Assegnate</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteName=='prenotazionenew' && currentRouteParams.tipo=='1')}">
                  <router-link tag="a" class="menu-link" :to="{name:'prenotazionenew',params:{type:1,tipo:1}}">
                    <div data-i18n="Container">Nuova per Azienda</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteName=='prenotazionenew' && currentRouteParams.tipo=='2')}">
                  <router-link tag="a" class="menu-link" :to="{name:'prenotazionenew',params:{type:1,tipo:2}}">
                    <div data-i18n="Fluid">Nuova per privato</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='blocchi' || currentRouteName=='blocconotturno' || currentRouteName=='bloccoedit' || currentRouteName=='blocconew'),open: (currentRouteName=='blocchi' || currentRouteName=='blocconotturno' || currentRouteName=='bloccoedit' || currentRouteName=='blocconew')}">
              <router-link tag="a" :to="{name:'blocchi'}" class="menu-link menu-toggle">
                <i class="menu-icon tf-icons bx bx-lock"></i>
                <div data-i18n="Account Settings">Blocco Prenotazioni</div>
              </router-link>
              <ul class="menu-sub">
                <li class="menu-item" :class="{active: currentRouteName=='blocchi' || currentRouteName=='bloccoedit' || currentRouteName=='blocconew'}">
                  <router-link tag="a" class="menu-link" :to="{name:'blocchi'}">
                    <div data-i18n="Without menu">Blocchi</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: currentRouteName=='blocconotturno'}">
                  <router-link tag="a" class="menu-link" :to="{name:'blocconotturno'}">
                    <div data-i18n="Without navbar">Notturno</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='notturno')}">
              <router-link tag="a" :to="{name:'notturno'}" class="menu-link">
                <i class="menu-icon tf-icons bx bx-purchase-tag"></i>
                <div data-i18n="Account Settings">Supplemento</div>
              </router-link>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='acquista' || currentRouteName=='hotels' || currentRouteName=='hotelslista' || currentRouteName=='hotelsnew' || currentRouteName=='hotelsedit'),open: (currentRouteName=='acquista' || currentRouteName=='hotels' || currentRouteName=='hotelslista' || currentRouteName=='hotelsnew' || currentRouteName=='hotelsedit')}">
              <router-link tag="a" :to="{name:'acquista'}" class="menu-link menu-toggle">
                <i class="menu-icon tf-icons bx bx-cog"></i>
                <div data-i18n="Account Settings">Acquista Online</div>
              </router-link>
              <ul class="menu-sub">
                <li class="menu-item" :class="{active: currentRouteName=='acquista'}">
                  <router-link tag="a" :to="{name:'acquista'}" class="menu-link">
                    <div data-i18n="Without menu">Dati generali e Terminal</div>
                  </router-link>
                </li>
                <li class="menu-item"  :class="{active: currentRouteName=='hotels'}">
                  <router-link tag="a" :to="{name:'hotels'}" class="menu-link">
                    <div data-i18n="Without navbar">Fasce Hotel</div>
                  </router-link>
                </li>
                <li class="menu-item"  :class="{active: currentRouteName=='hotelslista'}">
                  <router-link tag="a" :to="{name:'hotelslista'}" class="menu-link">
                    <div data-i18n="Without navbar">Lista Hotel</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item" :class="{active: currentRouteName=='pagamento'}">
              <router-link tag="a" :to="{name:'pagamento'}" class="menu-link">
                <i class="menu-icon tf-icons bx bx-credit-card-alt"></i>
                <div data-i18n="Account Settings">Richiedi Pagamento</div>
              </router-link>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='anagrafica' || currentRouteName=='serviziaggiuntivi' || currentRouteName=='serviziaggiuntivinew' || currentRouteName=='serviziaggiuntiviedit' || currentRouteName=='agenzie' || currentRouteName=='agenzienew'),open: (currentRouteName=='anagrafica' || currentRouteName=='serviziaggiuntivi' || currentRouteName=='serviziaggiuntivinew' || currentRouteName=='serviziaggiuntiviedit' || currentRouteName=='agenzie' || currentRouteName=='agenzienew' || currentRouteName=='listini' || currentRouteName=='listininew' || currentRouteName=='listiniedit')}">
              <router-link tag="a" :to="{name:'anagrafica'}" class="menu-link menu-toggle">
                <i class="menu-icon tf-icons bx bx-group"></i>
                <div data-i18n="Account Settings">Anagrafica</div>
              </router-link>
              <ul class="menu-sub">
                <li class="menu-item" :class="{active: currentRouteName=='anagrafica'}">
                  <router-link tag="a" class="menu-link" :to="{name:'anagrafica'}">
                    <div data-i18n="Without menu">Clienti</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteName=='serviziaggiuntivi' || currentRouteName=='serviziaggiuntivinew' || currentRouteName=='serviziaggiuntiviedit')}">
                  <router-link tag="a" class="menu-link" :to="{name:'serviziaggiuntivi'}">
                    <div data-i18n="Without navbar">Servizi Aggiuntivi</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteName=='listini') || currentRouteName=='listininew' || currentRouteName=='listiniedit'}">
                  <router-link tag="a" class="menu-link" :to="{name:'listini'}">
                    <div data-i18n="Container">Listini</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteName=='agenzie' || currentRouteName=='agenzienew')}">
                  <router-link tag="a" class="menu-link" :to="{name:'agenzie'}">
                    <div data-i18n="Fluid">Account Agenzie</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='percorsi' || currentRouteName=='percorsinew' || currentRouteName=='percorsiedit')}">
              <router-link tag="a" :to="{name:'percorsi'}" class="menu-link">
                <i class="menu-icon tf-icons bx bx-map-alt"></i>
                <div data-i18n="Account Settings">Percorsi</div>
              </router-link>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='mezzi' || currentRouteName=='mezzinew' || currentRouteName=='mezziedit')}">
              <router-link tag="a" :to="{name:'mezzi'}" class="menu-link">
                <i class="menu-icon tf-icons bx bxs-ship"></i>
                <div data-i18n="Account Settings">Mezzi</div>
              </router-link>
            </li>
            <li class="menu-header small text-uppercase">
              <span class="menu-header-text">Contabilità</span>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='riepilogodocumenti')}">
              <router-link tag="a" :to="{name:'riepilogodocumenti'}" class="menu-link">
                <i class="menu-icon tf-icons bx bxs-report"></i>
                <div data-i18n="Account Settings">Riepilogo Documenti</div>
              </router-link>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='fatture')}">
              <router-link tag="a" :to="{name:'fatture'}" class="menu-link">
                <i class="menu-icon tf-icons bx bxs-report"></i>
                <div data-i18n="Account Settings">Fatture di Vendita</div>
              </router-link>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='ddt'),open: (currentRouteName=='ddt')}">
              <router-link tag="a" :to="{name:'ddt', params:{type: 1}}" class="menu-link menu-toggle">
                <i class="menu-icon tf-icons bx bxs-report"></i>
                <div data-i18n="Account Settings">D.D.T.</div>
              </router-link>
              <ul class="menu-sub">
                <li class="menu-item" :class="{active: (currentRouteParams.type=='1' && (currentRouteName=='ddt' || currentRouteName=='ddtscheda'))}">
                  <router-link tag="a" class="menu-link" :to="{name:'ddt', params:{type: 1}}">
                    <div data-i18n="Without menu">Fatturati</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteParams.type=='2' && (currentRouteName=='ddt' || currentRouteName=='ddtscheda'))}">
                  <router-link tag="a" class="menu-link" :to="{name:'ddt', params:{type: 2}}">
                    <div data-i18n="Without navbar">Validati</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteParams.type=='3' && (currentRouteName=='ddt' || currentRouteName=='ddtscheda'))}">
                  <router-link tag="a" class="menu-link" :to="{name:'ddt', params:{type: 3}}">
                    <div data-i18n="Container">In Attesa</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteParams.type=='4' && (currentRouteName=='ddt' || currentRouteName=='ddtscheda'))}">
                  <router-link tag="a" class="menu-link" :to="{name:'ddt', params:{type: 4}}">
                    <div data-i18n="Fluid">Archiviati</div>
                  </router-link>
                </li>
                <li class="menu-item" :class="{active: (currentRouteParams.type=='5' && (currentRouteName=='ddt' || currentRouteName=='ddtscheda'))}">
                  <router-link tag="a" class="menu-link" :to="{name:'ddt', params:{type: 5}}">
                    <div data-i18n="Fluid">A Corrispettivo</div>
                  </router-link>
                </li>
              </ul>
            </li>
            <!--<li class="menu-item">
              <router-link tag="a" to="" class="menu-link">
                <i class="menu-icon tf-icons bx bxs-report"></i>
                <div data-i18n="Account Settings">D.D.T Merci</div>
              </router-link>
            </li>-->
            <li class="menu-item" :class="{active: (currentRouteName=='metodipagamento')}">
              <router-link tag="a" :to="{name:'metodipagamento'}" class="menu-link">
                <i class="menu-icon tf-icons bx bxs-report"></i>
                <div data-i18n="Account Settings">Metodi di pagamento</div>
              </router-link>
            </li>
            <li class="menu-item" :class="{active: (currentRouteName=='codiciiva')}">
              <router-link tag="a" :to="{name:'codiciiva'}" class="menu-link">
                <i class="menu-icon tf-icons bx bxs-report"></i>
                <div data-i18n="Account Settings">Codici IVA</div>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link tag="a" to="" class="menu-link">
                <i class="menu-icon tf-icons bx bx-buildings"></i>
                <div data-i18n="Account Settings">Aziende</div>
              </router-link>
            </li>
            <li class="menu-item">
              <router-link tag="a" to="" class="menu-link">
                <i class="menu-icon tf-icons bx bx-user"></i>
                <div data-i18n="Account Settings">Utenti</div>
              </router-link>
            </li>
            <li class="menu-item">
              <a class="menu-link">
                <i class="menu-icon tf-icons bx bx-log-out"></i>
                <div>Esci</div>
              </a>
            </li>
          </ul>
        </aside>
</template>

<script>
export default {
  name: 'MenuBar',
  props: {
    msg: String
  },
  computed: {
    currentRouteParams(){
      return this.$route.params;
    },
    currentRouteName() {
      return this.$route.name;
    }
  }
}
</script>