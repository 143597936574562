<template>
  <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
      <MenuBar v-show="logged"/>
      <div class="layout-page">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import MenuBar from './components/MenuBar'
export default {
  name: 'App',
  data() {
        return {
            logged:false
        };
  },
  mounted(){
    this.checklogin()
  },
  watch:{
    $route (to, from){
      this.checklogin()
    }
  },
  methods:{
    checklogin(){
            if(localStorage.getItem('vwtToken')!=undefined && localStorage.getItem('vwtToken')!=null){
                axios({
                    url: 'https://g.venicewatertaxi.it/api/validate',
                    method: 'GET',
                    headers: {
                        'token': localStorage.getItem("vwtToken"),
                        'uid': localStorage.getItem("vwtID"),
                        'Accept' : '*/*',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    }
                })
                .then(resp => {
                    if(resp.data.success){
                        this.logged = true
                    }
                })
                .catch(err => {
                    console.log(err)
                });
            }else{
              this.logged = false
              this.$router.push("/");
            }
        },
  },
  components: {
    MenuBar
  }
}
</script>